import axios from "axios"

export default () => {
  const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token") || null,
    },
  })

  return api
}
